<template>
  <b-form-group
    id="create-user-access"
    label="Access"
    label-for="create-user-access"
    label-size="lg"
    class="p-3 bg-white rounded shadow-sm mb-4"
  >
    <b-form-group label="Expires At">
      <b-form-input id="expiresAt" v-model="expiresAt" type="date" @input="updateParentField('expiresAt', expiresAt)" />
    </b-form-group>

    <b-tabs>
      <b-tab title="Groups" content-class="mt-2" active>
        <group-form :user-groups="userGroups" @update:userGroups="setNewMembership" />
      </b-tab>

      <b-tab title="Blocked Applications" content-class="mt-2">
        <blocked-applications
          :selected-applications="selectedBlockedApplications"
          @update:selectedApplications="updateSelectedApplications"
        />
      </b-tab>
    </b-tabs>

    <user-creation-navigation next-label="Create user" v-on="$listeners" />
  </b-form-group>
</template>

<script>
import GroupForm from '@/components/GroupForm'
import BlockedApplications from '@/components/BlockedApplications'
import UserCreationNavigation from '@/components/UserForm/UserCreationNavigation'

export default {
  name: 'AccessStep',
  components: {
    GroupForm,
    BlockedApplications,
    UserCreationNavigation
  },
  data() {
    return {
      expiresAt: '',
      userGroups: [],
      blockedApplications: [],
      selectedBlockedApplications: []
    }
  },
  methods: {
    updateParentField(field, value) {
      this.$emit('update-field', { field, value })
    },
    setNewMembership(updatedUserGroups) {
      this.userGroups = updatedUserGroups
      this.updateParentField('userGroups', this.userGroups)
    },
    updateSelectedApplications(newSelection) {
      this.selectedBlockedApplications = newSelection
      this.blockedApplications = newSelection.map((id) => ({ application_id: id }))
      this.updateParentField('blockedApplications', this.blockedApplications)
    }
  }
}
</script>
