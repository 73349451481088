<template>
  <div class="user-access-groups">
    <h3>Groups</h3>
    <div class="row">
      <xemx-form class="col-12 col-lg-8 mb-2" @submit.prevent="handleSubmit">
        <p class="text-muted">
          Adding a user to a group will grant them access to all the applications each group is associated to. To
          prevent a user from accessing specific applications, please change their
          <a href="#/access/blocked-applications">blocked applications list</a>.
        </p>
        <group-form
          :with-submit-button="true"
          :user-id="user.id"
          :user-groups="userGroups"
          @update:userGroups="setNewMembership"
        />
      </xemx-form>
    </div>
  </div>
</template>

<script>
import { cloneDeep } from 'lodash'

import XemxForm from '@/components/XemxForm'
import GroupForm from '@/components/GroupForm'

export default {
  name: 'UserAccessGroups',
  components: {
    XemxForm,
    GroupForm
  },
  inject: ['api'],
  props: {
    user: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      userGroups: cloneDeep(this.user.user_groups)
    }
  },
  computed: {
    root() {
      return `/users/${this.user.id}`
    },
    updateGroupsUrl() {
      return `${this.root}/update_groups`
    }
  },
  methods: {
    setNewMembership(updatedUserGroups) {
      this.userGroups = updatedUserGroups
    },
    async handleSubmit() {
      try {
        await this.api.updateGroups(this.updateGroupsUrl, this.userGroups)
      } finally {
        window.location.reload()
      }
    }
  }
}
</script>
