<template>
  <div class="onboarding-info card p-4 rounded-50">
    <h4 class="onboarding-info__title">
      <fa icon="circle-info" fixed-width class="mr-1" /><slot name="header">Information</slot>
    </h4>
    <div>
      <b-card-text class="onboarding-info__content">
        <slot name="content" />
      </b-card-text>
    </div>
  </div>
</template>
<script>
export default {
  name: 'OnboardingInfo'
}
</script>

<style lang="scss" scoped>
@import '../../utils/variables';

.onboarding-info {
  background-color: $gray-400;
  border-radius: 0.8em;
}
</style>
