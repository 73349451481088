<template>
  <b-form-group
    id="create-user-email"
    label="Email"
    label-for="create-user-email"
    label-size="lg"
    class="p-3 bg-white rounded shadow-sm mb-4"
  >
    <div v-if="errors.length" class="text-danger">
      <div v-for="(error, index) in errors" :key="index">{{ error }}</div>
    </div>

    <b-form-group label="Primary Email Address" label-for="email" label-class="control-label--required">
      <p class="mb-2 text-muted small">
        This email is used for all encrypted communications with the account and will have to be associated with a valid
        PGP key.
      </p>
      <b-form-input id="email" v-model="email" type="email" required @input="updateParentField('email', email)" />
    </b-form-group>

    <pgp-key-input :pgp-key="pgpKey" @input="updateParentField('pgpKey', pgpKey)">
      <template #label>Copy or drag and drop the user's PGP key in the input below.</template>
      <template #actions><span></span></template>
    </pgp-key-input>

    <user-creation-navigation :validate-step="validateEmail" v-on="$listeners" />
  </b-form-group>
</template>

<script>
import PgpKeyInput from '@/components/PgpKeyInput'
import UserCreationNavigation from '@/components/UserForm/UserCreationNavigation'

export default {
  name: 'EmailStep',
  components: {
    PgpKeyInput,
    UserCreationNavigation
  },
  data() {
    return {
      email: '',
      pgpKey: '',
      errors: []
    }
  },
  methods: {
    updateParentField(field, value) {
      this.$emit('update-field', { field, value })
    },
    validateEmail() {
      this.errors = []
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
      if (!emailRegex.test(this.email)) {
        this.errors.push('Please enter a valid email address.')
        return false
      }
      return true
    }
  }
}
</script>
