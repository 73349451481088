<template>
  <b-button
    v-if="!!type"
    :disabled="disabled"
    :type="type"
    size="lg"
    class="font-weight-bold"
    :variant="disabled ? 'secondary' : 'primary'"
    @click="onClick"
  >
    <fa v-if="iconLeft" icon="circle-right" fixed-width class="mr-1" />
    <slot />
    <fa v-if="!iconLeft" icon="circle-right" fixed-width class="mr-1" />
  </b-button>
  <a
    v-else
    :href="href"
    class="float-right my-4 font-weight-bold btn btn-primary btn-md"
    :class="{
      'disabled btn-secondary': disabled
    }"
  >
    <fa v-if="iconLeft" icon="circle-right" fixed-width class="mr-1" />
    <slot />
    <fa v-if="!iconLeft" icon="circle-right" fixed-width class="mr-1" />
  </a>
</template>
<script>
// TODO test: display proper type depending on prop
export default {
  name: 'OnboardingButton',
  props: {
    href: {
      type: String,
      default: ''
    },
    disabled: {
      type: Boolean,
      default: false
    },
    iconLeft: {
      type: Boolean,
      default: false
    },
    type: {
      type: String,
      default: undefined
    }
  },
  methods: {
    onClick() {
      this.$emit('click')
    }
  }
}
</script>
