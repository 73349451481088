<script>
import PermissionsTable from '@/components/PermissionsTable'

export default {
  name: 'UserAccessPermissions',
  components: {
    PermissionsTable
  },
  props: {
    user: {
      type: Object,
      required: true
    }
  }
}
</script>

<template>
  <div>
    <h3 class="mb-3">Permissions</h3>

    <permissions-table :user="user" class="user-access-permissions" />
  </div>
</template>

<style lang="scss">
.user-access-permissions {
  height: 40rem;
}
</style>
