<template>
  <b-form-group label="Username" label-for="uid">
    <p class="mb-2 text-muted small">Must be in lowercase, without space or special characters.</p>
    <b-form-input
      id="uid"
      v-model="uid"
      :disabled="disabled"
      pattern="[a-z]*"
      required
      :state="error ? false : null"
      @update="handleInput"
    ></b-form-input>
    <b-form-invalid-feedback>{{ error }}</b-form-invalid-feedback>
  </b-form-group>
</template>

<script>
export default {
  name: 'UidInput',
  model: {
    prop: 'value',
    event: 'update'
  },
  props: {
    value: {
      type: String,
      required: true
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      uid: this.value,
      error: ''
    }
  },
  watch: {
    value(newValue) {
      this.uid = newValue
      this.validateUid()
    }
  },
  methods: {
    validateUid() {
      this.error = ''

      if (this.uid === '') {
        this.error = 'Username is required.'
      } else if (/[A-Z]/.test(this.uid)) {
        this.error = 'Username must be in lowercase.'
      } else if (/\s/.test(this.uid)) {
        this.error = 'Username must not contain spaces.'
      } else if (/[^a-z]/.test(this.uid)) {
        this.error = 'Username must not contain special characters.'
      }

      this.$emit('uid-validation', this.error)
    },
    handleInput() {
      this.$emit('update', this.uid)
    }
  }
}
</script>
