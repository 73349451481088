<template>
  <b-form v-bind="$attrs" v-on="$listeners">
    <input type="hidden" name="authenticity_token" :value="authenticityToken" />
    <slot />
  </b-form>
</template>

<script lang="js">
import authenticityTokenMixin from '../mixins/authenticityToken'

export default {
  name: 'XemxForm',
  mixins: [authenticityTokenMixin]
}
</script>
