<template>
  <div class="p-3 bg-white rounded shadow-sm mb-4">
    <permissions-table :user="user" />
    <magic-link :user-id="user.id" />
  </div>
</template>

<script>
import PermissionsTable from '@/components/PermissionsTable'
import MagicLink from '@/components/MagicLink'

export default {
  name: 'RecapStep',
  components: {
    PermissionsTable,
    MagicLink
  },
  props: {
    user: {
      type: Object,
      required: true
    }
  }
}
</script>
