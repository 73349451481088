<template>
  <div class="d-flex justify-content-between mt-4">
    <b-button :class="previousButtonClass" type="button" variant="secondary" size="lg" @click="goToPrevious">
      {{ previousLabel }}
    </b-button>

    <b-button type="button" :variant="nextButtonVariant" size="lg" @click="goToNext">
      {{ nextLabel }}
    </b-button>
  </div>
</template>

<script>
export default {
  name: 'UserCreationNavigation',
  props: {
    showPrevious: {
      type: Boolean,
      default: true
    },
    previousLabel: {
      type: String,
      default: 'Previous'
    },
    nextLabel: {
      type: String,
      default: 'Next'
    },
    nextButtonVariant: {
      type: String,
      default: 'primary'
    },
    validateStep: {
      type: Function,
      default: () => true
    }
  },
  computed: {
    previousButtonClass() {
      return this.showPrevious ? 'visible' : 'invisible'
    }
  },
  methods: {
    goToPrevious() {
      this.$emit('previous-step')
    },
    goToNext() {
      if (this.validateStep()) {
        this.$emit('next-step')
      }
    }
  }
}
</script>
