<template>
  <div class="onboarding-page d-flex flex-column vh-100">
    <navbar class="">
      <template #right-menu>
        <b-button href="/users/sign_out" variant="link" class="text-dark mr-4">
          <i class="fa fa-sign-out-alt align-middle mr-1"></i>Log out</b-button
        >
      </template>
    </navbar>
    <slot name="flash" />
    <div class="container flex-grow-1 p-4">
      <component :is="currentStepComponent" :username="username" :email="email" :user-id="userId"></component>
    </div>
    <generic-footer></generic-footer>
  </div>
</template>
<script>
import { GenericFooter } from '@icij/murmur'

import Navbar from '@/components/Navbar'
import OnboardingStepWelcome from '@/components/onboarding/OnboardingStepWelcome'
import OnboardingStepEnable2FA from '@/components/onboarding/OnboardingStepEnable2FA'
import OnboardingStepCreatePassword from '@/components/onboarding/OnboardingStepCreatePassword'
import OnboardingStepConfirmAccount from '@/components/onboarding/OnboardingStepConfirmAccount'
import OnboardingStepPgp from '@/components/onboarding/OnboardingStepPgp'
import OnboardingStepEnjoy from '@/components/onboarding/OnboardingStepEnjoy'

export const STEPS = Object.freeze({
  WELCOME: 0,
  CREATE_PASSWORD: 1,
  PGP: 2,
  CONFIRM_ACCOUNT: 3,
  ENABLE_TWO_FA: 4,
  ENJOY: 5
})

const STEP_COMPONENTS = Object.freeze({
  [STEPS.WELCOME]: OnboardingStepWelcome,
  [STEPS.CREATE_PASSWORD]: OnboardingStepCreatePassword,
  [STEPS.PGP]: OnboardingStepPgp,
  [STEPS.CONFIRM_ACCOUNT]: OnboardingStepConfirmAccount,
  [STEPS.ENABLE_TWO_FA]: OnboardingStepEnable2FA,
  [STEPS.ENJOY]: OnboardingStepEnjoy
})

export default {
  name: 'OnboardingPage',
  components: {
    GenericFooter,
    Navbar
  },
  props: {
    userId: {
      type: Number,
      required: true
    },
    username: {
      type: String,
      required: true
    },
    email: {
      type: String,
      required: true
    },
    step: {
      type: Number,
      default: 1
    }
  },
  computed: {
    currentStepComponent() {
      return STEP_COMPONENTS[this.step]
    }
  }
}
</script>
