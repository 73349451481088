<template>
  <b-form-group
    id="create-user-profile"
    label="Profile"
    label-for="create-user-profile"
    label-size="lg"
    class="p-3 bg-white rounded shadow-sm mb-4"
  >
    <div v-if="errors.length" class="text-danger">
      <div v-for="(error, index) in errors" :key="index">{{ error }}</div>
    </div>

    <b-row>
      <b-col cols="12" md="6">
        <b-form-group label="First Name" label-for="firstName" label-class="control-label--required">
          <b-form-input
            id="firstName"
            v-model="firstName"
            required
            @input="updateParentField('firstName', firstName)"
          />
        </b-form-group>
      </b-col>

      <b-col cols="12" md="6">
        <b-form-group label="Last Name" label-for="lastName" label-class="control-label--required">
          <b-form-input id="lastName" v-model="lastName" required @input="updateParentField('lastName', lastName)" />
        </b-form-group>
      </b-col>
    </b-row>

    <uid-input :value="uid" @update="onUpdate" @uid-validation="onUidValidation" />

    <b-row>
      <b-col cols="12" md="6">
        <b-form-group label="Organization" label-for="organization" label-class="control-label--required">
          <b-form-input
            id="organization"
            v-model="organization"
            required
            @input="updateParentField('organization', organization)"
          />
        </b-form-group>
      </b-col>

      <b-col cols="12" md="6">
        <b-form-group label="Country" label-class="control-label--required">
          <country-select v-model="country" name="country" @input="updateParentField('country', country)" />
        </b-form-group>
      </b-col>
    </b-row>

    <user-creation-navigation :show-previous="false" :validate-step="validateAllFields" v-on="$listeners" />
  </b-form-group>
</template>

<script>
import CountrySelect from '@/components/CountrySelect'
import UidInput from '@/components/UserForm/UidInput'
import UserCreationNavigation from '@/components/UserForm/UserCreationNavigation'

const REQUIRED_FIELDS = ['firstName', 'lastName', 'organization', 'country']
export default {
  name: 'ProfileStep',
  components: {
    CountrySelect,
    UidInput,
    UserCreationNavigation
  },
  data() {
    return {
      uid: '',
      firstName: '',
      lastName: '',
      organization: '',
      country: '',
      uidEdited: false,
      errors: [],
      uidErrors: ''
    }
  },
  watch: {
    firstName(newFirst) {
      this.updateUid(newFirst, this.lastName)
    },
    lastName(newLast) {
      this.updateUid(this.firstName, newLast)
    }
  },
  methods: {
    updateParentField(field, value) {
      this.$emit('update-field', { field, value })
    },
    onUidValidation(error) {
      this.uidError = error
    },
    validateAllFields() {
      this.errors = []

      REQUIRED_FIELDS.forEach((field) => {
        if (this[field] === '') {
          this.errors.push(`${field.charAt(0).toUpperCase() + field.slice(1)} is required.`)
        } else {
          this.errors = this.errors.filter((error) => !error.includes(field))
        }
      })

      if (this.uidError) {
        this.errors.push(this.uidError)
      }

      return this.errors.length === 0
    },
    updateUid(firstName, lastName) {
      if (!this.uidEdited && firstName && lastName) {
        this.uid = `${firstName.charAt(0).toLowerCase()}${lastName.toLowerCase()}`
        this.updateParentField('uid', this.uid)
      }
    },
    onUpdate(value) {
      this.uidEdited = true
      this.uid = value
      this.updateParentField('uid', this.uid)
    }
  }
}
</script>
