<script>
export default {
  name: 'UserCreationProgressBar',
  props: {
    userCreationStepsArray: {
      type: Array,
      required: true
    },
    currentStep: {
      type: Number,
      required: true
    },
    steps: {
      type: Object,
      required: true
    }
  },
  computed: {
    classList() {
      return this.userCreationStepsArray.map((step) => {
        const isCompleted = this.currentStep > this.steps[step]
        const isLastActive = this.currentStep === this.userCreationStepsArray.length
        const isActive = isLastActive || this.currentStep === this.steps[step]

        return {
          'user-creation-progress-bar__list__item--completed': isCompleted,
          'user-creation-progress-bar__list__item--active': isActive,
          'user-creation-progress-bar__list__item--last-active': isLastActive
        }
      })
    }
  }
}
</script>

<template>
  <dl class="user-creation-progress-bar">
    <dt class="mb-3">User creation steps</dt>
    <dd class="bg-light py-4 px-3 rounded">
      <ol class="user-creation-progress-bar__list d-flex p-0 m-0">
        <li
          v-for="(step, index) in userCreationStepsArray"
          :key="step"
          :class="classList[index]"
          class="user-creation-progress-bar__list__item"
        >
          <span class="circle">{{ index + 1 }}</span>
          <span class="user-creation-progress-bar__list__item__label w-100 mt-3 pt-1 text-center">{{ step }}</span>
        </li>
      </ol>
    </dd>
  </dl>
</template>

<style lang="scss" scoped>
@import '../../utils/variables';

.user-creation-progress-bar {
  --counter-size: 1.5em;
  --line-height: 0.4em;

  &__list {
    list-style: none;
    counter-reset: user-creation-steps;

    &__item {
      counter-increment: user-creation-steps;
      display: flex;
      flex-wrap: wrap;
      flex: 1;
      position: relative;
      padding: var(--counter-size) $spacer 0;

      &::before,
      &::after {
        content: '';
        height: var(--line-height);
        background-color: $secondary;
        position: absolute;
        width: 50%;
        top: 0.6em;
        z-index: 5;
      }
      &::before {
        left: 0;
      }

      &::after {
        left: 50%;
      }

      &:first-child::before {
        border-top-left-radius: var(--line-height);
        border-bottom-left-radius: var(--line-height);
      }

      &:last-child::after {
        border-top-right-radius: var(--line-height);
        border-bottom-right-radius: var(--line-height);
      }

      .circle {
        color: $dark;
        background-color: $secondary;
        font-weight: bold;
        border-radius: 50%;
        height: var(--counter-size);
        line-height: var(--counter-size);
        width: var(--counter-size);
        position: absolute;
        left: 50%;
        top: 0;
        transform: translateX(-50%);
        text-align: center;
        z-index: 10;
      }

      &--completed {
        &::before,
        &::after,
        .circle {
          background-color: $primary;
        }
      }

      &--active {
        &::before,
        .circle {
          background-color: $primary;
        }
        &::after {
          background-color: $secondary;
        }
      }

      &--last-active {
        &::after {
          background-color: $primary !important;
        }
      }

      &__label {
        font-size: $font-size-base;
      }
    }
  }
}
</style>
