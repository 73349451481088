<script>
export default {
  name: 'GroupFormEntry',
  props: {
    groupId: {
      type: Number,
      required: true
    },
    groupLabel: {
      type: String,
      required: true
    },
    groupDescription: {
      type: String,
      default: ''
    },
    userGroup: {
      type: Object,
      default: () => {
        return {
          partner: false,
          _destroy: true
        }
      }
    }
  },
  computed: {
    isGroupMember() {
      // if destroy is not set, or if it is set to false,
      // the existence of this UserGroup entry means user is a member
      return this.userGroup?._destroy !== true
    },
    isPartner() {
      return this.userGroup.partner
    }
  },
  methods: {
    updateGroupMemberStatus(groupId, checked) {
      const userGroup = { groupId, partner: this.isPartner, _destroy: !checked }
      if (!checked) {
        userGroup.partner = false
      }
      this.$emit('update:userGroup', userGroup)
    },
    updatePartnerStatus(groupId, checked) {
      const userGroup = { groupId, partner: checked, _destroy: !this.isGroupMember }
      if (checked) {
        userGroup._destroy = false
      }
      this.$emit('update:userGroup', userGroup)
    }
  }
}
</script>

<template>
  <div class="pb-3">
    <div class="d-flex mb-2">
      <b-form-checkbox :checked="isGroupMember" @input="updateGroupMemberStatus(groupId, $event)">
        <strong>{{ groupLabel }}</strong>
      </b-form-checkbox>

      <b-form-checkbox switch class="ml-auto" :checked="isPartner" @input="updatePartnerStatus(groupId, $event)">
        Partner
      </b-form-checkbox>
    </div>
    <p class="small m-0 text-muted">{{ groupDescription }}</p>
  </div>
</template>
