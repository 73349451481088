<template>
  <div class="blocked-applications">
    <b-card no-body>
      <b-form-group>
        <div class="card-header">
          <b-form-checkbox v-model="checkAllApplications"> Select all applications </b-form-checkbox>
        </div>
        <b-overlay :show="busy" rounded class="blocked-applications__checkbox-group-overlay">
          <b-form-checkbox-group
            v-model="selectedApplicationsCopy"
            aria-label="Blocked applications"
            class="pl-3 pt-3"
            name="applications[]"
            stacked
            html-field="nameAndDescription"
            value-field="id"
            :options="formattedApplications"
          />
        </b-overlay>
      </b-form-group>
    </b-card>
  </div>
</template>

<script>
export default {
  name: 'BlockedApplications',
  inject: ['api'],
  props: {
    selectedApplications: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      applications: [],
      busy: false
    }
  },
  computed: {
    selectedApplicationsCopy: {
      get() {
        return this.selectedApplications
      },
      set(value) {
        this.$emit('update:selectedApplications', value)
      }
    },
    formattedApplications() {
      return this.applications.map((app) => ({
        ...app,
        nameAndDescription: `
            <strong>${app.name}</strong>
            <p class="small m-0 text-muted">${app.description}</p>
          `
      }))
    },
    checkAllApplications: {
      get() {
        return this.selectedApplications.length === this.applications.length
      },
      set(value) {
        const selectedIds = []

        if (value) {
          this.applications.forEach((app) => {
            selectedIds.push(app.id)
          })
        }

        // don't uncheck all the boxes if only one checkbox was unchecked
        if (
          value === false &&
          this.selectedApplications.length > 0 &&
          this.selectedApplications.length < this.applications.length
        ) {
          return
        }

        this.$emit('update:selectedApplications', selectedIds)
      }
    }
  },
  async created() {
    this.busy = true
    this.applications = await this.api.getOauthApplications()
    this.busy = false
  }
}
</script>

<style lang="scss" scoped>
.blocked-applications {
  &__checkbox-group-overlay .card {
    min-height: 5rem;
  }
}
</style>
